div.App[dir="rtl"] {
  html,
  body {
    font-family: $font-family-tajawal;
  }

  h1, h2, h3, h4, h5, h6, a, span, button, div:not(.english-font), label, p {
    font-family: $font-family-tajawal !important;
  }

  textarea, input {
    font-family: $font-family-tajawal !important;

    &::placeholder {
      font-family: $font-family-tajawal !important;
    }
  }

  .win-badge {
    left: 80px;
    right: auto !important;

    @media (max-width: $breakpoint-768) {
      left: 15px;
      width: 250px;
    }
  }

  .strip {
    .competition {
      padding: 15px 0 10px 0 !important;
    }
  }

  .lg-cta {
    svg {
      vertical-align: sub !important;
    }
  }

  header {
    .lg-nav {
      float: left;

      .navbar-nav {
        a {
          &.nav-link {
            font-family: $font-family-tajawal;
          }

          svg {
            vertical-align: sub !important;
          }
        }
      }
    }

    .hamburger-react {
      float: left;
    }
  }

  .page {
    //section {
    //  &.lg-section {
    //    &.lg-home-section {
    //      &.hero-section {
    //        .block {
    //          h1 {
    //            font-family: $font-family-tajawal;
    //          }
    //        }
    //      }
    //    }
    //  }
    //}
    &.page-post {
      .wizard {
        .wizard-step {
          .form-group {
            text-align: right;
          }

          &.wizard-step-3 {
            .inner-content {
              .board {
                .description {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }

    &.page-single-post {
      .actions {
        .inner {
          svg {
            margin-left: 2px;
            margin-right: 0;
          }
        }
      }
    }
    &.page-gallery {
      .gallery-section {
        .grid {
          .grid-item {
            .actions {
              .popup {
                right: auto;
                left: 0;

                &::after {
                  right: auto;
                  left: 8px;
                  bottom: -14px;
                }

                .popup-content {
                  a {
                    //border-top-right-radius: 0 !important;
                    //border-bottom-right-radius: 0 !important;
                    //border-right-color: transparent !important;
                    border-top-right-radius: .28571429rem !important;
                    border-bottom-right-radius: .28571429rem !important;
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                    border-right-color: rgba(34, 36, 38, .15) !important;
                    border-left-color: transparent !important;
                    text-align: right !important;
                    border: 1px solid rgba(34, 36, 38, .15);
                    border-radius: .28571429rem;
                  }
                }

                button {
                  border-radius: .28571429rem 0 0 .28571429rem !important;
                  //border-radius: 0 .28571429rem .28571429rem 0;
                  margin: 0 0 0 .25em !important;
                }
              }
            }
          }
        }
      }
    }
  }

  footer {
    .lg-nav {
      float: left;

      @media (max-width: $breakpoint-768) {
        float: none;
      }

      .navbar-nav {
        .nav-item {
          &:last-child {
            a {
              margin-left: 0;
            }
          }
        }
      }
    }

    .secondary-nav {
      @media (max-width: $breakpoint-992) {
        text-align: center;
        margin-bottom: 20px;
      }

      .nav-item {
        margin-left: 39px;
        margin-right: 0;

        @media (max-width: $breakpoint-1200) {
          margin-left: 20px;
        }
      }
    }

    .social-media {
      float: left;
      padding: 0;

      @media (max-width: $breakpoint-992) {
        float: none;
        text-align: center;
      }

      .icon {
        margin: 0 13px 0 auto;

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}