//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import './layout/fonts';

@import './core/base/functions';
@import './core/base/mixins';

// Bootstrap initialization
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";


// Custom layout variables
@import './layout/variables';


html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px !important;
  font-weight: 400;
  font-family: $font-family-lg-smart-ui-bold;

  // Tablet mode
  @include media-breakpoint-down(lg) {
    font-size: 18px !important;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: 14px !important;
  }
}

.win-badge {
  position: absolute;
  top: 50%;
  right: 80px;
  transform: translateY(-50%);
  z-index: 9999;
  width: 300px;
  filter: drop-shadow(9px 9px 5px rgba(255, 255, 255, 0.5));

  @media (max-width: $breakpoint-768) {
    right: 15px;
    width: 250px;
  }

  img {
    width: 100%;
  }
}