.page {
  &.page-post, &.page-single-post {
    //padding: 69px 0;
    height: calc(100vh - 80px);
    position: relative;

    background-image: url("../../images/sections/post-section-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: $breakpoint-768) {
      padding: 35px 0;
    }

    .align-v-center {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .wizard {
      //position: absolute;
      //width: 100%;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);

      .wizard-step {
        //padding: 44px;
        background-color: $white-color;
        border-radius: 6px;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 3px 16px rgba(0, 0, 0, 0.2);
        position: relative;
        height: 78vh;

        @media (max-width: $breakpoint-768) {
          height: auto;
          padding: 22px;
        }

        .inner-content {
          position: absolute;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);

          @media (max-width: $breakpoint-768) {
            position: static;
            transform: none;
          }
        }

        .title {
          margin-bottom: 54px;

          @media (max-width: $breakpoint-768) {
            margin-bottom: 27px;
          }

          h2 {
            font-family: $font-family-lg-ei-headline-bold;
            font-weight: 700;
            font-size: 18px;
            line-height: 44px;
            color: $burgundy-color;
            text-transform: uppercase;
            margin: 0;

            @media (max-width: $breakpoint-768) {
              font-size: 17px;
              line-height: 40px;
            }
          }

          p {
            font-family: $font-family-lg-smart-ui-semibold;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: $black-color;
            margin: 0;

            @media (max-width: $breakpoint-768) {
              font-size: 14px;
              line-height: 19px;
            }
          }
        }

        .form-group {
          text-align: left;
          margin-bottom: 35px;

          @media (max-width: $breakpoint-768) {
            margin-bottom: 16px;
          }

          label {
            font-family: $font-family-lg-smart-ui-semibold;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: $shark-color;
            margin: 0;
            margin-bottom: 11.5px;

            @media (max-width: $breakpoint-768) {
              font-size: 14px;
              line-height: 19px;
              margin-bottom: 5px;
            }
          }

          .form-control {
            border-radius: 8px !important;
            border: 1px solid $light-grey-color !important;
            background-color: $white-color !important;
            transition: all 0.25s;
            font-family: $font-family-lg-smart-ui-semibold;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $burgundy-color;

            @media (max-width: $breakpoint-768) {
              font-size: 15px;
              line-height: 23px;
            }

            &:focus {
              border: none !important;
              box-shadow: 0px 0px 3px 2px rgba(143, 143, 143, 0.2) !important;
            }

            &::placeholder {
              font-family: $font-family-lg-smart-ui-regular;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: $dove-gray-color;
              margin: 0;

              @media (max-width: $breakpoint-768) {
                font-size: 13px;
                line-height: 23px;
              }
            }
          }

          .text-danger {
            font-family: $font-family-lg-smart-ui-semibold;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            margin: 0;
          }
        }

        .actions {
          display: flex;
          justify-content: space-between;

          button {
            &:first-child {
              order: 1;
            }

            &:last-child {
              order: 2;
            }
          }
        }

        button {
          &.btn {
            &.lg-wizard-action-btn {
              border-radius: 39px;
              background-color: $shark-color;
              padding: 11px 28px;
              font-family: $font-family-segoe-ui-bold;
              font-weight: 700;
              font-size: 17.69px;
              line-height: 17.69px;
              color: $white-color;

              @media (max-width: $breakpoint-768) {
                font-size: 15px;
                line-height: 15px;

                padding: 5px 9px;
              }
            }

            &.lg-wizard-action-back-btn {
              border-radius: 0;
              background-color: transparent;
              padding: 0;
              font-family: $font-family-lg-smart-ui-bold;
              font-weight: 700;
              font-size: 15.38px;
              line-height: 20.8px;
              color: $grey-color;

              @media (max-width: $breakpoint-768) {
                font-size: 14px;
                line-height: 19px;
              }
            }

            &.lg-wizard-action-exit-btn {
              border-radius: 0;
              background-color: transparent;
              padding: 0;
              font-family: $font-family-lg-smart-ui-bold;
              font-weight: 700;
              font-size: 18px;
              line-height: 18px;
              color: $grey-color;

              @media (max-width: $breakpoint-768) {
                font-size: 16px;
                line-height: 16px;
              }
            }
          }
        }

        &.wizard-step-1 {
          text-align: left;
          padding: 12px 13px 46px;

          @media (max-width: $breakpoint-768) {
            padding: 6px 7px 22px;
          }

          .inner-content {
            text-align: center;
            padding: 0 35px;
          }
        }

        &.wizard-step-2 {
          text-align: left;
          padding: 12px 13px 44px;

          @media (max-width: $breakpoint-768) {
            padding: 6px 7px 22px;
          }

          .inner-content {
            text-align: center;

            .title {
              margin-bottom: 29px;

              @media (max-width: $breakpoint-768) {
                margin-bottom: 15px;
              }
            }

            .categories {
              margin-bottom: 50px;

              @media (max-width: $breakpoint-768) {
                margin-bottom: 25px;
              }

              button {
                margin: 0 15px;

                @media (max-width: $breakpoint-768) {
                  margin: 0;
                }
              }
            }

            .slick-slider {
              position: relative;
              margin-bottom: 25px;

              @media (max-width: $breakpoint-768) {
                margin-bottom: 12px;
              }

              .slick-list {
                .slick-track {
                  display: flex; /* Use flexbox */
                  align-items: center; /* Center vertically */

                  .slick-slide {
                    padding: 0 5px;
                    vertical-align: middle;
                    flex: 0 0 auto; /* Ensure all slides have the same width */

                    .slide {
                      cursor: pointer;

                      img {
                        border-radius: 5.65px;
                        box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.5);
                        width: 100%;
                      }
                    }

                    &.slick-center {
                      padding: 0 0;

                      img {
                        border: 3px solid $burgundy-color;
                      }
                    }
                  }
                }
              }

              .slick-dots {
                position: static;

                li {
                  margin: 0;

                  button {
                    padding: 0;

                    &:before {
                      color: $grey-color-4;
                      opacity: 1;
                    }
                  }

                  &.slick-active {
                    button {
                      &:before {
                        color: $burgundy-color;
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }

            .slick-slider::before,
            .slick-slider::after {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              width: 10%;
              z-index: 1;
            }

            .slick-slider::before {
              left: 0;
              background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
            }

            .slick-slider::after {
              right: 0;
              background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
            }

            .grid {
              display: grid;
              grid-template-columns: repeat(6, 1fr);
              gap: 10px;
              padding-bottom: 31px;

              .grid-item {
                position: relative;
                vertical-align: middle;

                img {
                  border-radius: 5.65px;
                  box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.5);
                  width: 100%;
                }

                &.slick-center {
                  transform: scale(1.1) !important;

                  img {
                    border: 3px solid $burgundy-color;
                    height: 100%;
                  }
                }
              }
            }
          }
        }

        &.wizard-step-3 {
          text-align: left;
          //padding: 12px 13px 44px;
          //
          //@media (max-width: $breakpoint-768) {
          //  padding: 6px 7px 22px;
          //}

          .actions {
            padding: 12px 13px 44px;

            @media (max-width: $breakpoint-768) {
              padding: 0;
            }
          }

          .inner-content {
            text-align: center;
            //height: 100%;
            padding: 0 35px;

            .row, [class^="col-"] {
              height: 100%;
            }

            @media (max-width: $breakpoint-768) {
              padding: 20px 0;
            }

            .title {
              margin-bottom: 29px;

              @media (max-width: $breakpoint-768) {
                margin-bottom: 15px;
              }
            }

            .board {
              .h-half {
                height: 240px;
                //height: 50%;
              }

              .description {
                font-family: $font-family-lg-smart-ui-regular;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                color: $dove-gray-color;
                text-align: left;
                margin: 0 0 10px;

                @media (max-width: $breakpoint-768) {
                  font-size: 14px;
                  line-height: 19px;
                  margin: 0 0 5px;
                }
              }

              .illustration-size {
                position: relative;
                width: 100%;
                height: 100%;

                max-height: 480px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 5.65px;
                  box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.5);
                }

                p {
                  position: absolute;
                  top: 11%;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 100%;
                  word-wrap: break-word; /* For older browsers */
                  overflow-wrap: break-word; /* For newer browsers */

                  font-family: $font-family-lg-smart-ui-bold;
                  font-weight: 700;
                  font-size: 70%;
                  line-height: 1.5em;
                  color: $white-color;
                  margin: 0;
                  padding: 15px;
                  text-align: center;

                  transition: all 0.3s;

                  @media (max-width: $breakpoint-768) {
                    font-size: 15px;
                    line-height: 15px;
                    padding: 7px;
                  }
                }

                //&.illustration-size-2 {
                //  p {
                //    font-size: 14px;
                //    line-height: 14px;
                //  }
                //}
                //
                //&.illustration-size-3 {
                //  p {
                //    font-size: 16px;
                //    line-height: 16px;
                //
                //    @media (max-width: $breakpoint-768) {
                //      font-size: 15px;
                //      line-height: 15px;
                //    }
                //  }
                //}
              }

              textarea {
                border-radius: 8px !important;
                border: 1px solid $light-grey-color;
                width: 100%;
                margin-top: 25px;
                margin-bottom: 25px;
                padding: 10px;
                transition: all 0.25s;
                font-family: $font-family-lg-smart-ui-semibold;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $black-color;
                resize: none;

                @media (max-width: $breakpoint-768) {
                  margin-top: 18px;
                  margin-bottom: 15px;
                  padding: 5px;
                }

                &::placeholder {
                  font-family: $font-family-lg-smart-ui-regular;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  color: $dove-gray-color;
                  margin: 0;
                  padding: 10px;

                  @media (max-width: $breakpoint-768) {
                    padding: 5px;
                  }
                }
              }
            }
          }
        }

        &.wizard-step-4 {
          text-align: left;
          padding: 12px 13px 44px;

          @media (max-width: $breakpoint-768) {
            padding: 6px 7px 22px;
          }

          .inner-content {
            text-align: center;
            padding: 0 20px;

            @media (max-width: $breakpoint-768) {
              padding: 0 10px;
            }

            .title {
              margin-bottom: 23px !important;

              @media (max-width: $breakpoint-768) {
                margin-bottom: 12px;
              }
            }

            .illustration-preview {
              position: relative;
              width: 160px;
              text-align: center;
              margin: 0 auto;
              margin-bottom: 23px !important;

              @media (max-width: $breakpoint-768) {
                margin-bottom: 12px;
              }

              img {
                width: 100%;
                border-radius: 6px;
                border: 4px solid #F6F6F6;
                box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.2);
              }
            }

            .caption {
              font-family: $font-family-lg-smart-ui-semibold;
              font-weight: 600;
              font-size: 15px;
              line-height: 18px;
              color: $black-color;
              margin: 0;
              margin-bottom: 19px;

              @media (max-width: $breakpoint-768) {
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 7px;
              }
            }
          }
        }
      }
    }
  }

  &.page-single-post {
    .wizard-step {
      position: relative;
    }

    .inner-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;

      @media (max-width: $breakpoint-768) {
        position: static;
        transform: none;
      }

      .illustration-preview {
        width: 70% !important;
      }
    }

    .actions {
      .like {
        &.liked {
          a {
            pointer-events: none;
          }

          svg {
            path {
              stroke: none;
              fill: $white-color;
            }
          }
        }
      }

      .inner {
        background-color: $burgundy-color;
        color: $white-color;
        padding: 11px 28px !important;
        border-radius: 39px;
        font-family: $font-family-segoe-ui-bold;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;

        @media (max-width: $breakpoint-768) {
          padding: 5px 9px !important;
        }

        svg {
          font-size: 20px;
          line-height: 20px;
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 2px;

          @media (max-width: $breakpoint-768) {
            font-size: 15px;
            line-height: 15px;
          }
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}