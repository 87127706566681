//
// Theme style
//

// Initialize
@import 'init';

@import "components/header";
@import "components/footer";
@import "components/buttons";

@import "sections/home";
@import "sections/gallery";
@import "sections/post";

@import "ar_styles";

html {
  scroll-behavior: smooth;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel img {
  margin: 0 5px; /* Adjust spacing between images */
  cursor: pointer;
}

.carousel img.active {
  border: 2px solid blue; /* Border style for active image */
  transform: scale(1.2); /* Larger size for active image */
}

.post-message {
  position: absolute;
  top: 11%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  word-wrap: break-word; /* For older browsers */
  overflow-wrap: break-word; /* For newer browsers */

  font-family: $font-family-lg-smart-ui-bold;
  font-weight: 700;
  font-size: 70%;
  line-height: 1.5em;
  color: $white-color;
  margin: 0;
  padding: 15px;
  text-align: center;

  //font-family: $font-family-lg-smart-ui-bold;
  //font-size: 15px;
  //line-height: 20px;
  //color: $black-color;
  //margin: 0;
  //
  //position: absolute;
  //top: 11%;
  //left: 50%;
  //transform: translateX(-50%);
  ////transform: translate(-50%, -50%);
  //
  //width: 80%;

  //@media (max-width: $breakpoint-992) {
  //  color: rgb(255, 255, 255);
  //  font-size: 13px;
  //  line-height: 18px;
  //  //top: 50%;
  //  //transform: translate(-50%, -50%);
  //}
  //
  //@media (max-width: $breakpoint-768) {
  //  color: rgb(255, 255, 255);
  //  font-size: 13px;
  //  line-height: 18px;
  //  //top: 50%;
  //  //transform: translate(-50%, -50%);
  //}

  &.dark-post {
    color: $black-color;
  }

  &.light-post {
    color: $white-color;
  }
}


.popup {
  position: absolute;
  bottom: 122%;
  right: 0;
  left: auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  //margin-top: 8px;
  z-index: 10;
}

.popup::after {
  content: '';
  position: absolute;
  border: solid transparent;
  border-width: 8px;
  border-top-color: #ffffff;
  pointer-events: none;
  right: 8px;
  bottom: -14px;
}


.progress-container {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0; /* Background color of the progress container */
}

.progress-bar {
  height: 100%;
  width: 0;
  background-color: $burgundy-color; /* Progress bar color */
  animation: progressAnimation 2s linear infinite; /* Customize animation duration and timing */
}

@keyframes progressAnimation {
  0% {
    width: 0;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}


.language-switcher {
  border: 2px solid $black-color !important;
  background-color: $white-color !important;
  transition: all 0.25s;
  font-family: $font-family-lg-smart-ui-semibold;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $black-color;

  &:focus {
    border: none !important;
    box-shadow: 0px 0px 3px 2px rgba(143, 143, 143, 0.2) !important;
  }
}

.lg-select {
  font-family: $font-family-segoe-ui-bold;
  font-weight: 700;
  color: $white-color;
  border-radius: 39px;
  font-size: 15px;
  line-height: 20px;

  @media (max-width: $breakpoint-768) {
    width: 50%;
    margin: 0 auto;
  }

  .css-t3ipsp-control {
    border-color: $burgundy-color !important;
    box-shadow: 0 0 0 1px $burgundy-color !important;
    //box-shadow: none !important;
  }

  .css-1nmdiqs-menu {
    background-color: yellow !important;
  }
}

.dark-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
}

.light-shadow {
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
}

.arabic-font {
  font-family: $font-family-tajawal !important;
}

.english-font {
  font-family: $font-family-lg-smart-ui-bold !important;
}

.d-none-m {
  @media (max-width: $breakpoint-576) {
    display: none !important;
  }
}

.text-burgundy {
  color: $burgundy-color !important;
}