.page {
  &.page-home {
    width: 100%;
    //height: calc(100vh - #{$header-height});

    .gallery-section {
      padding: 80px 0;

      @media (max-width: $breakpoint-768) {
        padding: 40px 0 !important;
      }

      .block {
        .grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-auto-rows: minmax(316px, auto); /* Minimum height of 100px */
          gap: 10px; /* Adjust gap between columns */
          padding: 67px 0;

          @media (max-width: $breakpoint-992) {
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: minmax(158px, auto); /* Minimum height of 100px */
            gap: 5px; /* Adjust gap between columns */
            padding: 30px 0;
          }

          @media (max-width: $breakpoint-768) {
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: minmax(158px, auto); /* Minimum height of 100px */
            gap: 5px; /* Adjust gap between columns */
            padding: 30px 0;
          }

          .grid-item {
            background-color: #f0f0f0; /* Adjust background color */
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden; /* Ensure images don't overflow */
            position: relative; /* Needed for absolute positioning */
            border-radius: 12px;
            box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.5);

            img {
              position: absolute; /* Position images absolutely */
              top: 50%; /* Move images to the top */
              left: 50%; /* Move images to the left */
              transform: translate(-50%, -50%); /* Center images */
            }
          }
        }
      }
    }
  }

  .categories {
    button {
      font-family: $font-family-segoe-ui-bold;
      font-weight: 700;
      font-size: 15px;
      line-height: 15px;
      color: $grey-color;
      margin: 0 30px;
      padding: 10px 30px;
      background: transparent;
      border: 0;
      transition: 0.25s ease-in-out;
      border-radius: 39px;

      @media (max-width: $breakpoint-992) {
        font-size: 14px;
        line-height: 14px;
        margin: 0 10px;
        padding: 5px 10px;
      }

      @media (max-width: $breakpoint-768) {
        font-size: 14px;
        line-height: 14px;
        margin: 0;
        padding: 5px 10px;
      }

      &:hover, &:active, &.active {
        color: $white-color;
        background-color: rgba(0, 0, 0, 0.7) !important;
      }
    }
  }
}

section {
  width: 100%;

  &.lg-section {
    h3 {
      font-family: $font-family-lg-smart-ui-bold;
      font-weight: 700;
      font-size: 41px;
      line-height: 41px;
      color: $black-color;
      margin: 0;

      @media (max-width: $breakpoint-1200) {
        font-size: 40px;
        line-height: 40px;
      }

      @media (max-width: $breakpoint-992) {
        font-size: 38px;
        line-height: 38px;
      }

      @media (max-width: $breakpoint-768) {
        font-size: 35px;
        line-height: 35px;
      }
    }

    h4 {
      font-family: $font-family-lg-ei-headline-bold;
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      color: $burgundy-color;
      text-transform: uppercase;
      margin-bottom: 10px;

      @media (max-width: $breakpoint-1200) {
        font-size: 20px;
        line-height: 20px;
      }

      @media (max-width: $breakpoint-992) {
        font-size: 19px;
        line-height: 19px;
      }

      @media (max-width: $breakpoint-768) {
        font-size: 18px;
        line-height: 18px;
      }
    }

    &.lg-home-section {
      &.hero-section {
        //padding: 95px 0;
        height: 100%;

        display: flex;
        justify-content: center; /* horizontally center */
        align-items: center; /* vertically center */

        position: relative;
        background-image: url("../../images/sections/hero-section-bg.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @media (max-width: $breakpoint-768) {
          text-align: center;
          padding: 45px 0;
        }

        @media (max-width: $breakpoint-576) {
          text-align: center;
          padding: 35px 0;
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 10px; /* Adjust spacing as needed */

          @media (max-width: 768px) {
              flex-direction: column; /* Stack items vertically on smaller screens */
          }
        }

        .strip {
          background-color: $black-color;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;

          .competition {
            font-family: $font-family-lg-smart-ui-bold;
            font-weight: 700;
            color: $white-color !important;
            font-size: 22px;
            line-height: 22px;
            width: 100% !important;
            padding: 10px 0;

            &.mb-0 {
              margin-bottom: 0 !important;
            }

            @media (max-width: $breakpoint-768) {
              font-size: 18px;
              line-height: 18px;
            }
          }
        }

        .slider-container {
          .slick-slider {
            .slide {
              img {
                width: 100%;
              }
            }
          }
        }

        .v-carousel {
          height: 500px;
          overflow: hidden;
        }

        .block {
          position: relative;
          width: 100%;
          height: 100%;

          @media (max-width: $breakpoint-768) {
            margin-bottom: 25px;
          }

          &.block-image {
            @media (max-width: $breakpoint-576) {
              width: 50vw;
              margin: 0 auto;
            }
          }

          .aligning-block {
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: $breakpoint-768) {
              position: static;
              transform: none;
            }
          }

          h1 {
            font-family: $font-family-lg-smart-ui-bold;
            font-weight: 700;
            font-size: 67px;
            line-height: 67px;
            color: $black-color;
            margin-bottom: 10px;

            @media (max-width: $breakpoint-1200) {
              font-size: 62px;
              line-height: 62px;
            }

            @media (max-width: $breakpoint-992) {
              font-size: 57px;
              line-height: 57px;
            }

            @media (max-width: $breakpoint-768) {
              font-size: 55px;
              line-height: 55px;
            }
          }

          h2 {
            font-family: $font-family-lg-ei-headline-bold;
            font-weight: 700;
            font-size: 33px;
            line-height: 33px;
            color: $burgundy-color;
            text-transform: uppercase;
            margin-bottom: 39px;

            @media (max-width: $breakpoint-1200) {
              font-size: 30px;
              line-height: 30px;
            }

            @media (max-width: $breakpoint-992) {
              font-size: 28px;
              line-height: 28px;

              margin-bottom: 30px;
            }

            @media (max-width: $breakpoint-768) {
              font-size: 25px;
              line-height: 25px;

              margin-bottom: 25px;
            }
          }

          p {
            font-family: $font-family-lg-smart-ui-regular;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: $black-color;
            margin-bottom: 37px;
            width: 70%;

            @media (max-width: $breakpoint-992) {
              font-size: 14px;
              line-height: 19px;
              width: 100%;
              margin-bottom: 30px;
            }

            @media (max-width: $breakpoint-768) {
              font-size: 14px;
              line-height: 19px;
              width: 100%;
              margin-bottom: 25px;
            }
          }

          img {
            width: 100%;
          }
        }
      }

      &.gallery-section {
        padding: 0 0 115px 0;

        @media (max-width: $breakpoint-768) {
          padding: 0 !important;
        }

        .block {
          h3 {
            margin-bottom: 30px;
          }
        }

        .banner {
          margin-top: -10px;
          padding-bottom: 50px;
          margin-right: -1px;

          img {
            width: 100%;
          }
        }
      }

      &.products-section {
        padding: 110px 0;
        background-color: #F6F3EB;

        @media (max-width: $breakpoint-992) {
          padding: 50px 0;
        }

        @media (max-width: $breakpoint-768) {
          padding: 40px 0;
        }

        .products {
          margin-top: 49px;

          @media (max-width: $breakpoint-992) {
            margin-top: 30px;
          }

          @media (max-width: $breakpoint-768) {
            margin-top: 24px;
          }

          .product {
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 19px;
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 3px 16px rgba(0, 0, 0, 0.2);
            text-align: center;
            padding: 25px;

            @media (max-width: $breakpoint-992) {
              padding: 18px;
            }

            @media (max-width: $breakpoint-768) {
              padding: 12px;
            }

            .product-image {
              width: 290px;
              margin: 0 auto;
              margin-bottom: 38px;

              @media (max-width: $breakpoint-1200) {
                width: 200px;
                margin-bottom: 30px;
              }

              @media (max-width: $breakpoint-992) {
                width: 150px;
                margin-bottom: 19px;
              }

              @media (max-width: $breakpoint-768) {
                width: 250px;
                margin-bottom: 19px;
              }

              img {
                width: 100%;
              }
            }

            .product-title {
              font-family: $font-family-lg-smart-ui-bold;
              font-weight: 700;
              font-size: 19px;
              line-height: 19px;
              color: $black-color;
              margin-bottom: 28px;

              @media (max-width: $breakpoint-992) {
                font-size: 16px;
                line-height: 16px;
                margin-bottom: 20px;
              }

              @media (max-width: $breakpoint-768) {
                font-size: 15px;
                line-height: 15px;
                margin-bottom: 19px;
              }
            }
          }
        }
      }
    }
  }
}