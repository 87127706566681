footer {
  padding: 40px 0;

  @media (max-width: $breakpoint-768) {
    padding: 25px 0;
  }

  .logo-container {
    width: 100px;

    @media (max-width: $breakpoint-1200) {
      width: 85px;
      //margin: 0 auto 20px;
    }

    @media (max-width: $breakpoint-768) {
      width: 75px;
      margin: 0 auto 20px;
    }

    img {
      width: 100%;
    }
  }

  .lg-nav {
    float: right;
    padding: 0;
    margin-bottom: 28px;

    @media (max-width: $breakpoint-768) {
      float: none;
      margin-bottom: 20px;
      justify-content: center;
    }

    .navbar-nav {
      @media (max-width: $breakpoint-768) {
        justify-content: center;
      }

      .nav-item {
        &:last-child {
          a {
            background-color: $burgundy-color;
            font-family: $font-family-segoe-ui-bold;
            font-weight: 700;
            color: $white-color;
            border-radius: 39px;
            font-size: 17px;
            line-height: 17px;
            margin-right: 0;
            padding: 11px 28px;

            @media (max-width: $breakpoint-1200) {
              font-size: 16px;
              line-height: 16px;
              padding: 8px 12px;
            }

            @media (max-width: $breakpoint-992) {
              font-size: 15px;
              line-height: 15px;
            }

            @media (max-width: $breakpoint-768) {
              font-size: 12px;
              line-height: 12px;
            }

            &.dark {
              background-color: $black-color;
            }

            &:hover {
              background-color: $boulder-color;
            }
          }
        }
      }

      a {
        position: relative;
        display: inline-block;
        padding-bottom: 5px; /* Adjust this value to change the thickness of the line */
        transition: all 0.3s; /* Animation duration */

        &.nav-link {
          font-family: $font-family-lg-smart-ui-semibold;
          font-weight: 600;
          font-size: 19px;
          line-height: 19px;
          color: $black-color;
          padding: 11px 0;
          margin: 0 20px;

          @media (max-width: $breakpoint-1200) {
            text-align: center;
            font-size: 16px;
            line-height: 16px;
            padding: 8px 0px;
            margin: 0 8px;
          }

          @media (max-width: $breakpoint-992) {
            text-align: center;
            font-size: 16px;
            line-height: 16px;
            padding: 5px 9px;
            margin: 0;
          }

          @media (max-width: $breakpoint-768) {
            text-align: center;
            font-size: 14px;
            line-height: 14px;
            padding: 5px 5px;
            margin: 0;
            display: block;
          }
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 2px; /* Height of the line */
          background-color: $burgundy-color; /* Color of the line */
          transition: width 0.3s; /* Animation duration */
        }

        &:last-child {
          &:hover {
            &:after {
              width: 0;
            }
          }
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  .secondary-nav {
    @media (max-width: $breakpoint-992) {
      text-align: center;
      margin-bottom: 20px;
    }

    .nav-item {
      font-family: $font-family-segoe-ui-regular;
      font-weight: 400;
      color: $grey-color-2;
      border-radius: 39px;
      font-size: 12.91px;
      line-height: 12.91px;
      font-weight: 400;
      margin-right: 39px;
      transition: all 0.3s;

      @media (max-width: $breakpoint-1200) {
        font-size: 11px;
        line-height: 11px;
        margin-right: 20px;
      }

      @media (max-width: $breakpoint-992) {
        display: block;
        margin: 15px 0;
      }

      @media (max-width: $breakpoint-768) {
        display: block;
        margin: 10px 0;
      }
    }

    a {
      &.nav-item {
        &:hover {
          color: $burgundy-color;
        }
      }
    }
  }

  .social-media {
    float: right;
    padding: 0;

    @media (max-width: $breakpoint-992) {
      float: none;
      text-align: center;
    }

    .icon {
      background-color: $grey-color-2;
      border-radius: 50%;
      color: $white-color;
      display: inline-block;
      width: 35px;
      height: 35px;
      font-size: 20px;
      line-height: 20px;
      position: relative;
      margin: 0 13px 0 auto;
      transition: all 0.3s;

      @media (max-width: $breakpoint-768) {
        margin: 0 5px;
      }

      @media (max-width: $breakpoint-768) {
        margin: 0 10px;
      }

      &:last-child {
        margin-right: 0;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        background-color: $burgundy-color;
      }
    }
  }
}