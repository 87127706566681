.cta {
  &.lg-cta {
    a {
      background-color: $burgundy-color;
      font-family: $font-family-segoe-ui-bold;
      font-weight: 700;
      font-size: 17px;
      line-height: 17px;
      color: $white-color;
      border-radius: 39px;
      padding: 11px 28px !important;
      transition: all 0.3s;

      @media (max-width: $breakpoint-992) {
        font-size: 16px;
        line-height: 16px;
        padding: 7px 20px !important;
      }

      @media (max-width: $breakpoint-768) {
        font-size: 15px;
        line-height: 15px;
        padding: 5px 18px !important;
      }

      &:hover {
        background-color: $boulder-color;
      }

      span {
        vertical-align: middle;
      }
    }
  }

  &.lg-load-more-cta {
    a {
      background-color: transparent;
      font-family: $font-family-segoe-ui-bold;
      font-weight: 700;
      font-size: 17px;
      line-height: 17px;
      color: $burgundy-color;
      border-radius: 39px;
      padding: 11px 28px !important;
      border: 2px solid $black-color;
      transition: all 0.3s;

      @media (max-width: $breakpoint-992) {
        font-size: 16px;
        line-height: 16px;
        padding: 7px 20px !important;
      }

      @media (max-width: $breakpoint-768) {
        font-size: 15px;
        line-height: 15px;
        padding: 5px 18px !important;
      }

      &:hover {
        background-color: $black-color;
        color: $white-color;
        //border: 2px solid $burgundy-color;
      }
    }
  }

  &.lg-view-product-cta {
    a {
      background-color: $burgundy-color;
      font-family: $font-family-segoe-ui-bold;
      font-weight: 700;
      font-size: 13px;
      line-height: 13px;
      color: $white-color;
      border-radius: 39px;
      padding: 11px 28px !important;
      //border: 2px solid $burgundy-color;
      transition: 0.25s ease-in-out;

      @media (max-width: $breakpoint-992) {
        font-size: 12px;
        line-height: 12px;
        padding: 7px 20px !important;
      }

      @media (max-width: $breakpoint-768) {
        font-size: 12px;
        line-height: 12px;
        padding: 5px 18px !important;
      }

      &:hover {
        background-color: $black-color;
        //color: $burgundy-color;
        //border: 2px solid $burgundy-color;
      }
    }
  }

  &.lg-our-products-cta {
    width: 50%;
    margin: 0 auto;

    a {
      background-color: $burgundy-color;
      font-family: $font-family-segoe-ui-bold;
      font-weight: 700;
      font-size: 17px;
      line-height: 24px;
      color: $white-color;
      border-radius: 39px;
      padding: 11px 28px !important;
      //border: 2px solid $burgundy-color;
      transition: all 0.3s;

      @media (max-width: $breakpoint-992) {
        font-size: 16px;
        line-height: 16px;
        padding: 7px 20px !important;
      }

      @media (max-width: $breakpoint-768) {
        font-size: 15px;
        line-height: 19px;
        padding: 5px 18px !important;
      }

      &:hover {
        background-color: $black-color;
        //color: $burgundy-color;
        //border: 2px solid $burgundy-color;
      }
    }
  }
}