$header-height: 80px;

header {
  height: $header-height;
  padding: 18px 0;

  @media (max-width: $breakpoint-768) {
    height: 60px;
    padding: 10px 0;
  }

  .logo-container {
    width: 100px;
    position: relative;
    z-index: 99999999999;

    @media (max-width: $breakpoint-1200) {
      width: 85px;
    }

    @media (max-width: $breakpoint-768) {
      width: 75px;
    }

    img {
      width: 100%;
    }
  }

  .lg-nav {
    float: right;
    padding: 0;

    @media (max-width: $breakpoint-768) {
      display: none;
    }

    .navbar-nav {
      .nav-item {
        &:nth-child(3) {
          a {
            background-color: $burgundy-color;
            font-family: $font-family-segoe-ui-bold;
            font-weight: 700;
            color: $white-color;
            border-radius: 39px;
            font-size: 17px;
            line-height: 17px;
            //margin-right: 0;
            padding: 11px 28px;
            transition: all 0.3s;

            @media (max-width: $breakpoint-1200) {
              font-size: 14px;
              line-height: 24px;
              padding: 5px 10px;
            }

            &:hover {
              background-color: $boulder-color;
            }

            &:hover, &.active {
              &:after {
                width: 0;
              }
            }

            &.active {
              background-color: $boulder-color;
            }
          }
        }
      }

      a {
        position: relative;
        display: inline-block;
        padding-bottom: 5px; /* Adjust this value to change the thickness of the line */
        transition: padding-bottom 0.3s; /* Animation duration */

        &.nav-link {
          font-family: $font-family-lg-smart-ui-semibold;
          font-weight: 600;
          font-size: 19px;
          line-height: 19px;
          color: $black-color;
          margin: 0 18px;
          padding: 11px 0;

          @media (max-width: 1400px) {
            font-size: 16px;
            line-height: 21px;
            padding: 10px 0;
            margin: 0 5px;
          }
          
          @media (max-width: $breakpoint-992) {
            font-size: 15px;
            line-height: 20px;
            padding: 5px 0;
          }

          span {
            vertical-align: middle;
          }
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 2px; /* Height of the line */
          background-color: $burgundy-color; /* Color of the line */
          transition: width 0.3s; /* Animation duration */
        }

        &:hover, &.active {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  .lg-nav-mobile {
    display: none;

    @media (max-width: $breakpoint-768) {
      display: block;
    }
  }

  .hamburger-react {
    width: 32px !important;
    height: 32px !important;
    z-index: 999999999;
    float: right;

    div {
      left: 0 !important;

      &:nth-child(1) {
        top: 0 !important;
      }

      &:nth-child(2) {
        top: 10px !important;
      }

      &:nth-child(3) {
        top: 20px !important;
      }
    }
  }

  .nav-links-mobile {
    width: 100vw;
    padding: 50% 0;
    //padding: 12px 0;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    background-color: white;
    height: 100%;
    transition: all .5s;
    z-index: 99999;

    li {
      list-style: none;
      margin-bottom: 25px;

      a {
        font-family: $font-family-lg-smart-ui-semibold;
        font-weight: 600;
        font-size: 19px;
        line-height: 19px;
        color: $black-color;
        padding: 0;
        margin: 0;
        text-decoration: none;

        span {
          vertical-align: middle;
        }
      }

      &:nth-child(3) {
        width: 70%;
        margin: 0 auto 25px;

        a {
          padding: 5px 12px 10px;
          background-color: $burgundy-color;
          font-family: $font-family-segoe-ui-bold;
          font-weight: 700;
          color: $white-color;
          border-radius: 39px;
          font-size: 17px;
          line-height: 17px;
          margin-right: 0;
        }
      }
    }
  }
}