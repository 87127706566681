.page {
  &.page-gallery {
    background-color: #F6F3EB;

    .gallery-section {
      .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px; /* Adjust gap between columns */
        padding: 60px 0;

        @media (max-width: $breakpoint-992) {
          grid-template-columns: repeat(3, 1fr);
          gap: 5px; /* Adjust gap between columns */
          padding: 30px 0;
        }

        @media (max-width: $breakpoint-768) {
          grid-template-columns: repeat(2, 1fr);
          gap: 5px; /* Adjust gap between columns */
          padding: 30px 0;
        }

        .grid-item {
          background-color: #f0f0f0; /* Adjust background color */
          border-radius: 12px;
          box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.5);
          position: relative;

          .lg-modal {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7); /* Adjust overlay background color and transparency */
            z-index: 9999;
            overflow: auto; /* Enable scrolling if content overflows */
            padding: 0; /* Set padding to 0 */
            box-sizing: border-box; /* Include padding and border in element's total width and height */
          }

          img {
            border-radius: 12px;
            width: 100%;
          }

          .actions {
            position: absolute;
            bottom: 26px;
            right: 33px;

            @media (max-width: $breakpoint-768) {
              left: 50%;
              right: auto;
              transform: translateX(-50%);
              width: 100%;
            }

            .like, .share{
              background-color: rgba(255,255,255,0.3);
              border-radius: 33px;
              padding: 6px;
              display: inline-block;
              vertical-align: middle;

              .inner {
                padding: 10px;
                background-color: $white-color;
                border-radius: 33px;

                font-family: $font-family-segoe-ui-bold;
                font-weight: 700;
                font-weight: 700;
                font-size: 14px;
                line-height: 14px;
                color: $black-color;

                a {
                  color: $black-color;
                }

                svg {
                  font-size: 20px;
                  line-height: 20px;
                  display: inline-block;
                  vertical-align: middle;

                  width: 20px;
                  height: 20px;
                }

                span {
                  display: inline-block;
                  vertical-align: middle;
                }

                .count {
                  color: $burgundy-color;
                }
              }
            }

            a {
              &.liked {
                pointer-events: none;

                svg {
                  //fill: $burgundy-color;

                  path {
                    //fill: $burgundy-color;
                    stroke: $burgundy-color;
                  }
                }
              }
            }

            .share {
              border-radius: 50%;
              padding: 6px;
              margin-left: 8px;

              .inner {
                border-radius: 50%;
                padding: 10px;

                svg {
                  color: $burgundy-color;
                }
              }
            }

            .popup {
              .popup-content {
                position: relative;
                font-weight: 400;
                font-style: normal;
                display: -ms-inline-flexbox;
                display: inline-flex;
                color: rgba(0, 0, 0, .87);
                font-size: 14px;

                a {
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  border-right-color: transparent !important;
                  margin: 0;
                  max-width: 100%;
                  -webkit-box-flex: 1;
                  -ms-flex: 1 0 auto;
                  flex: 1 0 auto;
                  outline: 0;
                  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
                  text-align: left;
                  line-height: 1.21428571em;
                  font-family: $font-family-segoe-ui-regular;
                  font-weight: 400;
                  padding: .67857143em 1em;
                  background: #fff;
                  border: 1px solid rgba(34, 36, 38, .15);
                  color: rgba(0, 0, 0, .87);
                  border-radius: .28571429rem;
                  -webkit-transition: border-color .1s ease, -webkit-box-shadow .1s ease;
                  transition: border-color .1s ease, -webkit-box-shadow .1s ease;
                  transition: box-shadow .1s ease, border-color .1s ease;
                  transition: box-shadow .1s ease, border-color .1s ease, -webkit-box-shadow .1s ease;
                  -webkit-box-shadow: none;
                  box-shadow: none;

                  @media (max-width: $breakpoint-1200) {
                    padding: 5px;
                    width: 150px;
                    overflow: hidden;
                    font-size: 14px;
                  }

                  @media (max-width: $breakpoint-992) {
                    padding: 5px;
                    width: 120px;
                    overflow: hidden;
                    font-size: 13px;
                  }

                  @media (max-width: $breakpoint-768) {
                    padding: 5px;
                    width: 100px;
                    overflow: hidden;
                    font-size: 12px;
                  }
                }
              }

              button {
                padding: 0;
                border: none;
                background: none;
                border-radius: 0 .28571429rem .28571429rem 0;
                padding-top: .78571429em;
                padding-bottom: .78571429em;
                margin: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                padding: .78571429em .78571429em .78571429em;
                font-size: 1rem;
                cursor: pointer;
                display: inline-block;
                min-height: 1em;
                outline: 0;
                border: none;
                vertical-align: baseline;
                background: #e0e1e2 none;
                //background: #e0e1e2 none;
                color: rgba(0, 0, 0, .6);
                margin: 0 .25em 0 0;
                padding: .78571429em 1.5em .78571429em;
                text-transform: none;
                text-shadow: none;
                font-weight: 700;
                line-height: 1em;
                font-style: normal;
                text-align: center;
                text-decoration: none;
                //border-radius: .28571429rem;
                -webkit-box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, .15) inset;
                box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, .15) inset;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-transition: opacity .1s ease, background-color .1s ease, color .1s ease, background .1s ease, -webkit-box-shadow .1s ease;
                transition: opacity .1s ease, background-color .1s ease, color .1s ease, background .1s ease, -webkit-box-shadow .1s ease;
                transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease;
                transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease, -webkit-box-shadow .1s ease;
                will-change: '';
                -webkit-tap-highlight-color: transparent;

                @media (max-width: $breakpoint-1200) {
                  padding: 10px;
                }

                @media (max-width: $breakpoint-992) {
                  padding: 10px;
                }

                @media (max-width: $breakpoint-768) {
                  padding: 10px;
                }

                &.copied {
                  color: $burgundy-color;
                }
              }
            }
          }
        }
      }
    }

    .info-section {
      background-image: url("../../images/sections/info-section-bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      padding: 114px 0;
      text-align: center;

      .block {
        width: 50%;
        margin: 0 auto;

        h1 {
          font-family: $font-family-lg-smart-ui-bold;
          font-weight: 700;
          font-size: 67px;
          line-height: 60px;
          color: $black-color;
          margin-bottom: 30px;

          @media (max-width: $breakpoint-768) {
            font-size: 33px;
            line-height: 33px;
            margin-bottom: 15px;
          }
        }

        p {
          font-family: $font-family-lg-smart-ui-regular;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: $black-color;
          margin-bottom: 40px;
          text-align: center;

          @media (max-width: $breakpoint-768) {
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}