//
// Layout Config
//

//// Small devices (landscape phones, 576px and up)
//@media (min-width: 576px) { ... }
//
//// Medium devices (tablets, 768px and up)
//@media (min-width: 768px) { ... }
//
//// Large devices (desktops, 992px and up)
//@media (min-width: 992px) { ... }
//
//// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }

// Breakpoints
$breakpoint-576: "576px";
$breakpoint-768: "768px";
$breakpoint-992: "992px";
$breakpoint-1200: "1200px";

// Root font Sizes
$root-font-size: 13px; // Root font size for desktop mode
$root-font-size-lg: 13px; // Root font size for tablet mode
$root-font-size-md: 12px; // Root font size for mobile mode

// Page bg
$page-bg: $gray-100 !default;
$page-bg-dark: #151521 !default;

// App blank bg color
$app-blank-bg-color: $white;
$app-blank-bg-color-dark: #151521;

// Content border radius
$content-border-radius: 1.5rem !default;

// Content Spacing
$content-spacing: (
        desktop: 30px,
  // Padding for desktop mode
        tablet-and-mobile: 15px // Padding for tablet and mobile modes,,
) !default;

// header
$header-config: (
        height: (
                desktop: 74px,
                tablet-and-mobile: 55px,
        ),
        z-index: 100,
        bg-color: $white,
        bg-color-dark: #1e1e2d,
        border-bottom: 0,
        border-bottom-dark: 1px solid #2d2d43,
        box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05),
        box-shadow-dark: none,
) !default;

// Theme colors
// Primary
$primary: #009ef7;
$primary-active: #0095e8;
$primary-light: #f1faff;
$primary-light-dark: #212e48;
$primary-inverse: #ffffff;

// Success
$success: #50cd89;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: #ffffff;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: #ffffff;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: #ffffff;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: #ffffff;

$black-color: #000000;
$white-color: #FFFFFF;
$burgundy-color: #A50034;
$grey-color: #727272;
$grey-color-2: #333333;
$grey-color-3: #8F8F8F;
$grey-color-4: #D9D9D9;
$shark-color: #212529;
$light-grey-color: #CED2DA;
$dove-gray-color: #666666;
$boulder-color: #767676;

$font-family-lg-smart-ui-bold: 'LG_Smart_UI-Bold', 'sans-serif' !default;
$font-family-lg-smart-ui-light: 'LG_Smart_UI-Light', 'sans-serif' !default;
$font-family-lg-smart-ui-regular: 'LG_Smart_UI-Regular', 'sans-serif' !default;
$font-family-lg-smart-ui-semibold: 'LG_Smart_UI-SemiBold', 'sans-serif' !default;
$font-family-tajawal: 'Tajawal', 'sans-serif' !default;

$font-family-lg-smart-ui-number-bold: 'LG_Smart_UI_NUMBER-Bold', 'sans-serif' !default;
$font-family-lg-smart-ui-number-light: 'LG_Smart_UI_NUMBER-Light', 'sans-serif' !default;
$font-family-lg-smart-ui-number-regular: 'LG_Smart_UI_NUMBER-Regular', 'sans-serif' !default;
$font-family-lg-smart-ui-number-semibold: 'LG_Smart_UI_NUMBER-SemiBold', 'sans-serif' !default;

$font-family-lg-ei-headline-bold: 'LG_EI_Headline-Bold', 'sans-serif' !default;
$font-family-lg-ei-headline-regular: 'LG_EI_Headline-Regular', 'sans-serif' !default;

$font-family-segoe-ui-bold: 'Segoe_UI_Bold', 'sans-serif' !default;
$font-family-segoe-ui-regular: 'Segoe_UI_Regular', 'sans-serif' !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `13px`
$font-size-lg: $font-size-base * 1.075 !default; // 14.04px
$font-size-sm: $font-size-base * 0.95 !default; // 12.025px

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 500 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 700 !default;

$font-weight-base: $font-weight-normal !default;

// Line heights
$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 1.75 !default;

// Keenthemes custom line heights
$line-height-xl: 2 !default;
$line-height-xxl: 2.25 !default;

// Heading sizes
$h1-font-size: $font-size-base * 1.75 !default; // 22.75px
$h2-font-size: $font-size-base * 1.5 !default; // 19.50px
$h3-font-size: $font-size-base * 1.35 !default; // 17.55px
$h4-font-size: $font-size-base * 1.25 !default; // 16.25px
$h5-font-size: $font-size-base * 1.15 !default; // 14.95px
$h6-font-size: $font-size-base * 1.075 !default; // 13.97px