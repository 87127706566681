// LG_Smart_UI font

@font-face {
  font-family: 'LG_Smart_UI-Bold';
  src: url('../../fonts/LG_Smart_UI/Bold/LG_Smart_UI-Bold.ttf') format('truetype'),
  url('../../fonts/LG_Smart_UI/Bold/LG_Smart_UI-Bold.woff') format('woff'),
  url('../../fonts/LG_Smart_UI/Bold/LG_Smart_UI-Bold.woff2') format('woff2'),
  url('../../fonts/LG_Smart_UI/Bold/LG_Smart_UI-Bold.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'LG_Smart_UI-Light';
  src: url('../../fonts/LG_Smart_UI/Light/LG_Smart_UI-Light.ttf') format('truetype'),
  url('../../fonts/LG_Smart_UI/Light/LG_Smart_UI-Light.woff') format('woff'),
  url('../../fonts/LG_Smart_UI/Light/LG_Smart_UI-Light.woff2') format('woff2'),
  url('../../fonts/LG_Smart_UI/Light/LG_Smart_UI-Light.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'LG_Smart_UI-Regular';
  src: url('../../fonts/LG_Smart_UI/Regular/LG_Smart_UI-Regular.ttf') format('truetype'),
  url('../../fonts/LG_Smart_UI/Regular/LG_Smart_UI-Regular.woff') format('woff'),
  url('../../fonts/LG_Smart_UI/Regular/LG_Smart_UI-Regular.woff2') format('woff2'),
  url('../../fonts/LG_Smart_UI/Regular/LG_Smart_UI-Regular.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'LG_Smart_UI-SemiBold';
  src: url('../../fonts/LG_Smart_UI/SemiBold/LG_Smart_UI-SemiBold.ttf') format('truetype'),
  url('../../fonts/LG_Smart_UI/SemiBold/LG_Smart_UI-SemiBold.woff') format('woff'),
  url('../../fonts/LG_Smart_UI/SemiBold/LG_Smart_UI-SemiBold.woff2') format('woff2'),
  url('../../fonts/LG_Smart_UI/SemiBold/LG_Smart_UI-SemiBold.eot') format('embedded-opentype');
}


// LG_Smart_UI_NUMBER font

@font-face {
  font-family: 'LG_Smart_UI_NUMBER-Bold';
  src: url('../../fonts/LG_Smart_UI_NUMBER/Bold/LG_Smart_UI_NUMBER-Bold.ttf') format('truetype'),
  url('../../fonts/LG_Smart_UI_NUMBER/Bold/LG_Smart_UI_NUMBER-Bold.woff') format('woff'),
  url('../../fonts/LG_Smart_UI_NUMBER/Bold/LG_Smart_UI_NUMBER-Bold.woff2') format('woff2'),
  url('../../fonts/LG_Smart_UI_NUMBER/Bold/LG_Smart_UI_NUMBER-Bold.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'LG_Smart_UI_NUMBER-Light';
  src: url('../../fonts/LG_Smart_UI_NUMBER/Light/LG_Smart_UI_NUMBER-Light.ttf') format('truetype'),
  url('../../fonts/LG_Smart_UI_NUMBER/Light/LG_Smart_UI_NUMBER-Light.woff') format('woff'),
  url('../../fonts/LG_Smart_UI_NUMBER/Light/LG_Smart_UI_NUMBER-Light.woff2') format('woff2'),
  url('../../fonts/LG_Smart_UI_NUMBER/Light/LG_Smart_UI_NUMBER-Light.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'LG_Smart_UI_NUMBER-Regular';
  src: url('../../fonts/LG_Smart_UI_NUMBER/Regular/LG_Smart_UI_NUMBER-Regular.ttf') format('truetype'),
  url('../../fonts/LG_Smart_UI_NUMBER/Regular/LG_Smart_UI_NUMBER-Regular.woff') format('woff'),
  url('../../fonts/LG_Smart_UI_NUMBER/Regular/LG_Smart_UI_NUMBER-Regular.woff2') format('woff2'),
  url('../../fonts/LG_Smart_UI_NUMBER/Regular/LG_Smart_UI_NUMBER-Regular.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'LG_Smart_UI_NUMBER-SemiBold';
  src: url('../../fonts/LG_Smart_UI_NUMBER/SemiBold/LG_Smart_UI_NUMBER-SemiBold.ttf') format('truetype'),
  url('../../fonts/LG_Smart_UI_NUMBER/SemiBold/LG_Smart_UI_NUMBER-SemiBold.woff') format('woff'),
  url('../../fonts/LG_Smart_UI_NUMBER/SemiBold/LG_Smart_UI_NUMBER-SemiBold.woff2') format('woff2'),
  url('../../fonts/LG_Smart_UI_NUMBER/SemiBold/LG_Smart_UI_NUMBER-SemiBold.eot') format('embedded-opentype');
}


// Main_Font font

@font-face {
  font-family: 'LG_EI_Headline-Bold';
  src: url('../../fonts/Main_Font/Bold/LG_EI_Headline-Bold.ttf') format('truetype'),
  url('../../fonts/Main_Font/Bold/LG_EI_Headline-Bold.woff') format('woff'),
  url('../../fonts/Main_Font/Bold/LG_EI_Headline-Bold.woff2') format('woff2'),
  url('../../fonts/Main_Font/Bold/LG_EI_Headline-Bold.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'LG_EI_Headline-Regular';
  src: url('../../fonts/Main_Font/Regular/LG_EI_Headline-Regular.ttf') format('truetype'),
  url('../../fonts/Main_Font/Regular/LG_EI_Headline-Regular.woff') format('woff'),
  url('../../fonts/Main_Font/Regular/LG_EI_Headline-Regular.woff2') format('woff2'),
  url('../../fonts/Main_Font/Regular/LG_EI_Headline-Regular.eot') format('embedded-opentype');
}

// Segio_UI font

@font-face {
  font-family: 'Segoe_UI_Bold';
  src: url('../../fonts/SegioUI/Bold/Segoe_UI_Bold.ttf') format('truetype'),
  url('../../fonts/SegioUI/Bold/Segoe_UI_Bold.woff') format('woff'),
  url('../../fonts/SegioUI/Bold/Segoe_UI_Bold.woff2') format('woff2'),
  url('../../fonts/SegioUI/Bold/Segoe_UI_Bold.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Segoe_UI_Regular';
  src: url('../../fonts/SegioUI/Regular/Segoe_UI_Regular.ttf') format('truetype'),
  url('../../fonts/SegioUI/Regular/Segoe_UI_Regular.woff') format('woff'),
  url('../../fonts/SegioUI/Regular/Segoe_UI_Regular.woff2') format('woff2'),
  url('../../fonts/SegioUI/Regular/Segoe_UI_Regular.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Tajawal';
  src: url('../../fonts/Tajawal/Tajawal-Regular.ttf') format('truetype'),
  url('../../fonts/Tajawal/Tajawal-Regular.woff') format('woff'),
  url('../../fonts/Tajawal/Tajawal-Regular.woff2') format('woff2'),
  url('../../fonts/Tajawal/Tajawal-Regular.eot') format('embedded-opentype');
}